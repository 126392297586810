<div class="header-container">
    <span fxLayoutAlign="space-between center" fxLayout fxLayout.xs="column" fxLayoutAlign.xs="center end">
        <!-- <span [ngStyle.gt-xs]="{'padding': '10px 0 10px 10px'}">Phone No. 9412488264 | 8791929495</span> -->
<div><img src="assets/logo/header logo.png" class="logo" alt="logo"></div> 

<div fxLayout fxLayoutAlign="end center" class="nav-container">
    <a href="https://wa.me/message/7CWIWDN2Z7KQA1" target="_blank"><img src="assets/whatsapp-icon/WhatsApp_Logo.png"></a>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu for knowing officeDetails and aboutUs"
        [disableRipple]="true">
        <mat-icon>subject</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <a mat-menu-item [routerLink]="['/kakaKohlu']">
            <mat-icon>home</mat-icon>
            <span>Home</span>
        </a>
        <a mat-menu-item [routerLink]="['/OfficeDetails']">
            <mat-icon>apartment</mat-icon>
            <span>Contact Us</span>
        </a>
        <a mat-menu-item [routerLink]="['/aboutUs']">
            <mat-icon>people_alt</mat-icon>
            <span>About Us</span>
        </a>
    </mat-menu>
</div>
</span>

</div>
