<div style="height:50px;background-color: #e40822;">

</div>
<div fxLayout="column" fxLayoutGap="30px" class="footer-container">
    <div fxLayout="row" fxLayoutGap="20px">
<!-- if required -->
        <div>
            <app-read-more *ngFor="let item of items" [content]="item" [limit]="300" [completeWords]="true">
            </app-read-more>
        </div>
    </div>
    <div fxLayoutAlign.lt-md="center center" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="20px" >
        <div><img src="assets/logo/logo.png" class="logo" alt="logo"></div> 
        <div *ngFor="let data of footer">
            <b>{{data.Heading}}</b>
            <ul>
                <li>{{data.ListItem1}}</li>
                <li>{{data.ListItem2}}</li>
                <!-- <li>{{data.ListItem3}}</li> -->
                <li>{{data.ListItem4}}</li>
                <!-- <li>{{data.ListItem5}}</li> -->
            </ul>
        </div>
        <div>
            <b>Registered Office Address</b>
            <div fxLayout="column" fxLayoutGap="9px" class="office-address">
                <div>
                    Mahaveer Ganj<br>
                    Aligarh, UttarPradesh 202001</div>
                <div> Email: Kakakohlu@gmail.com </div>
                <div> Telephone: 9412488264, 8791929495</div>
            </div>
        </div>
    </div>
    <div fxLayoutAlign="space-between center" fxLayout.lt-md="column" fxLayoutGap.lt-md="15px" fxLayout
    fxLayoutAlign.lt-md="center center">
        <div fxLayout fxFlex.xs fxLayoutGap="15px">
            <a href="https://www.facebook.com/kakakohlu?mibextid=ZbWKwL" target="_blank"><mat-icon svgIcon="facebook"></mat-icon></a>
            <a href="https://www.instagram.com/kakakohlu?igsh=MTIzamFnOG42bHd2dg==" target="_blank"><mat-icon svgIcon="instagram"></mat-icon></a>
            <a href="#"><mat-icon svgIcon="linkedin"></mat-icon></a>
            <a href="#"><mat-icon svgIcon="twitter"></mat-icon></a>
        </div>
        <strong fxLayout>
            <span>&#169;</span>
            &nbsp;
            <span>Kakakohlu - All Right Reserved</span>
        </strong>
        <div fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign.lt-md="center center">
            <span class="image-container" fxLayoutAlign="center center" *ngFor="let data of images">
                <img src="{{data.image}}" width="50px" height="30px">
            </span>
        </div>
    </div>
</div>